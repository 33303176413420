import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class EquipmentBreakdownInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="General Liability Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "General Liability Insurance helps cover your Louisiana based business's liability for third-party property damage and injury resulting from your daily operations. Contact us for a business insurance quote!" }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} alt="Baton Rouge business office with computer equipment" />
        <Callout
          title="General Liability Insurance"
          content="While every Louisiana business has unique risks, some of the biggest problems your company can face are often very common for most businesses. General Liability Insurance helps cover your Louisiana based business's liability for third-party property damage and injury resulting from your daily operations."
        />
        <div className="site-body page-content">
          <h2>What General Liability Insurance Usually Covers</h2>
          <p>
          <ul>
          <li><strong>Third-Party Bodily Injury.</strong> When a customer slips and falls or gets hurt on your property, this helps cover medical and legal expenses if you are sued.</li>
          <li><strong>Third-Party Property Damage.</strong> If you damage a customer's property, such as a smartphone or laptop, this helps pay for repair or replacement costs.</li>
          <li><strong>Product Liability.</strong> If your product hurts someone, such as a hot coffee burn, this will help pay for legal expenses.</li>
          <li><strong>Advertising Liability.</strong> If you are sued over slander or copyright claims this will help pay for your legal defense.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="Based on a 2005 study, it was found that a small business lawsuit normally costs between $3,000 and $150,000."
            source="United States Small Business Administration"
          />
          <h2>What General Liability Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li><strong>Professional errors.</strong> This is covered under a professional liability policy.</li>
          <li><strong>Employee injuries.</strong> This is covered in a variety of policies including workers compensation.</li>
          <li><strong>Damage to your business property.</strong> This is covered under commercial property insurance.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a General Liability Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default EquipmentBreakdownInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "general-liability-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-4.jpg" }) {
      ...mainBg
    }
  }
`;
